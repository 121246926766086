
import { Component, Vue, Watch } from "vue-property-decorator";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { MarketplaceRegistrationRequest } from "@jfrog-ba/myjfrog-common";
import { marketplaceRouteMeta } from "@/types/localtypes";

@Component({
  name: "CloudAccount",
  $_veeValidate: { validator: "new" },
  components: { JFFormControl, JFTextField },
})
export default class CloudAccount extends Vue {
  data: MarketplaceRegistrationRequest = {
    firstName: "",
    lastName: "",
    companyName: "",
    phone: "",
    email: "",
    regionCode: "",
    regionName: "",
    password: "",
    serverName: "",
    subscriptionType: "",
    mpToken: "",
    awsToken: "",
    instanceUrl: "",
    cloudProvider: "",
    termsAgree: false,
    awsAccountId: "",
  };
  token = "";
  routeParentChildMeta: marketplaceRouteMeta = {};

  created() {
    let token = this.$route.query.token as string;
    token = decodeURIComponent(token).replaceAll(" ", "+");
    this.token = token;
    this.getRouteParentChildMeta();
  }

  mounted() {
    this.loadFormDataFromSession();
  }

  getRouteParentChildMeta() {
    this.$route.matched.forEach(m => {
      for (let key in m.meta) {
        this.routeParentChildMeta[key] = m.meta[key];
      }
    });
  }

  loadFormDataFromSession() {
    const data = sessionStorage.getItem("registrationFormData");
    if (data) {
      this.data = JSON.parse(data);
      setTimeout(async () => {
        await this.$validator.validateAll();
      }, 300);
    }
  }

  get header() {
    return this.routeParentChildMeta.subscriptionType === "AWS_ENTERPRISE_PLUS"
      ? "Register to Enterprise+"
      : "Register to Enterprise";
  }

  @Watch("data.awsAccountId")
  onDataChange() {
    setTimeout(() => {
      if (this.errors.any()) {
        this.$emit("awsAccountIdFilled", null);
      } else {
        this.$emit("awsAccountIdFilled", this.data);
      }
      this.data.mpToken = this.token;
      this.data.awsToken = this.token;
      sessionStorage.setItem("registrationFormData", JSON.stringify(this.data));
    }, 300);
  }
}
